import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const EditRequestModal = ({ isOpen, onClose, request, refreshList }) => {
  const [title, setTitle] = useState(request?.title || "");
  const [description, setDescription] = useState(request?.description || "");
  const [priority, setPriority] = useState(request?.priority || "Medium");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedData = {
      title,
      description,
      priority,
    };

    try {
      await axios.patch(
        `https://api.monappliclub.com/api/requests/updateRequest/${request.id}`,
        updatedData
      );
      toast.success("Requête mise à jour avec succès !");
      refreshList(); // Rafraîchit la liste des requêtes après modification
      onClose(); // Ferme la modale
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la requête :", error);
      toast.error("Erreur lors de la mise à jour de la requête.");
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-lg shadow-lg">
        <h2 className="text-xl font-bold mb-4">Modifier la Requête</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block font-bold mb-2" htmlFor="title">
              Titre
            </label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold mb-2" htmlFor="description">
              Description
            </label>
            <ReactQuill
              theme="snow"
              value={description}
              onChange={setDescription} // Remplace le textarea
              className="rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold mb-2" htmlFor="priority">
              Priorité
            </label>
            <select
              id="priority"
              value={priority}
              onChange={(e) => setPriority(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="High">Urgent</option>
              <option value="Medium">Moyenne</option>
              <option value="Low">Basse</option>
            </select>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              disabled={loading}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              disabled={loading}
            >
              {loading ? "En cours..." : "Modifier"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditRequestModal;
